import React from "react"

import PageWrapper from "../components/PageWrapper"

import PrivacyPolicy from "../features/LegalStuff/PrivacyPolicy"

const PrivacyPolicyStandalonePage = ({ location }) => {
  return (
    <PageWrapper location={location} isStandalonePage>
      <PrivacyPolicy />
    </PageWrapper>
  )
}

export default PrivacyPolicyStandalonePage
